//@ts-nocheck
/**
 * Generated by orval v6.12.0 🍺
 * Do not edit manually.
 * Datek eCommerce API
 * Datek eCommerce API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { customInstance } from '../../httpClient'
import type { ErrorType } from '../../httpClient'
import type {
  FindUsers200,
  FindUsersDefault,
  FindUsersParams,
  GetMe200,
  GetMeDefault,
  GetUserDefault,
  InviteUserBody,
  InviteUserDefault,
  UpdateMe200,
  UpdateMeBody,
  UpdateMeDefault,
  UpdateUserBody,
  UpdateUserDefault,
  User,
} from '.././model'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never

export const getMe = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<GetMe200>({ url: `/api/users/me`, method: 'get', signal }, options)
}

export const getGetMeQueryKey = () => [`/api/users/me`]

export type GetMeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeInfiniteQueryError = ErrorType<GetMeDefault>

export const useGetMeInfinite = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<GetMeDefault>,
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) =>
    getMe(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof getMe>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeQueryError = ErrorType<GetMeDefault>

export const useGetMe = <
  TData = Awaited<ReturnType<typeof getMe>>,
  TError = ErrorType<GetMeDefault>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) =>
    getMe(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getMe>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const updateMe = (
  updateMeBody: UpdateMeBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpdateMe200>(
    {
      url: `/api/users/me`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeBody,
    },
    options,
  )
}

export type UpdateMeMutationResult = NonNullable<Awaited<ReturnType<typeof updateMe>>>
export type UpdateMeMutationBody = UpdateMeBody
export type UpdateMeMutationError = ErrorType<UpdateMeDefault>

export const useUpdateMe = <TError = ErrorType<UpdateMeDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMe>>,
    TError,
    { data: UpdateMeBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMe>>,
    { data: UpdateMeBody }
  > = (props) => {
    const { data } = props ?? {}

    return updateMe(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateMe>>,
    TError,
    { data: UpdateMeBody },
    TContext
  >(mutationFn, mutationOptions)
}
export const findUsers = (
  params?: FindUsersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<FindUsers200>({ url: `/api/users`, method: 'get', params, signal }, options)
}

export const getFindUsersQueryKey = (params?: FindUsersParams) => [
  `/api/users`,
  ...(params ? [params] : []),
]

export type FindUsersInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof findUsers>>>
export type FindUsersInfiniteQueryError = ErrorType<FindUsersDefault>

export const useFindUsersInfinite = <
  TData = Awaited<ReturnType<typeof findUsers>>,
  TError = ErrorType<FindUsersDefault>,
>(
  params?: FindUsersParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof findUsers>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFindUsersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findUsers>>> = ({ signal, pageParam }) =>
    findUsers({ _cursor: pageParam, ...params }, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof findUsers>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type FindUsersQueryResult = NonNullable<Awaited<ReturnType<typeof findUsers>>>
export type FindUsersQueryError = ErrorType<FindUsersDefault>

export const useFindUsers = <
  TData = Awaited<ReturnType<typeof findUsers>>,
  TError = ErrorType<FindUsersDefault>,
>(
  params?: FindUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof findUsers>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFindUsersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findUsers>>> = ({ signal }) =>
    findUsers(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof findUsers>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getUser = (
  userId?: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<User>({ url: `/api/users/${userId}`, method: 'get', signal }, options)
}

export const getGetUserQueryKey = (userId?: number) => [`/api/users/${userId}`]

export type GetUserInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserInfiniteQueryError = ErrorType<GetUserDefault>

export const useGetUserInfinite = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<GetUserDefault>,
>(
  userId?: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(userId, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<GetUserDefault>

export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<GetUserDefault>,
>(
  userId?: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(userId, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const updateUser = (
  updateUserBody: UpdateUserBody,
  userId?: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/users/${userId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserBody,
    },
    options,
  )
}

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
export type UpdateUserMutationBody = UpdateUserBody
export type UpdateUserMutationError = ErrorType<UpdateUserDefault>

export const useUpdateUser = <TError = ErrorType<UpdateUserDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserBody; userId?: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { data: UpdateUserBody; userId?: number }
  > = (props) => {
    const { data, userId } = props ?? {}

    return updateUser(data, userId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserBody; userId?: number },
    TContext
  >(mutationFn, mutationOptions)
}
export const inviteUser = (
  inviteUserBody: InviteUserBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/api/users/invite`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: inviteUserBody,
    },
    options,
  )
}

export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUser>>>
export type InviteUserMutationBody = InviteUserBody
export type InviteUserMutationError = ErrorType<InviteUserDefault>

export const useInviteUser = <TError = ErrorType<InviteUserDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviteUser>>,
    TError,
    { data: InviteUserBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviteUser>>,
    { data: InviteUserBody }
  > = (props) => {
    const { data } = props ?? {}

    return inviteUser(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof inviteUser>>,
    TError,
    { data: InviteUserBody },
    TContext
  >(mutationFn, mutationOptions)
}
