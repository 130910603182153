import '@/styles/globals.css'

import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { usePreserveScroll } from '@/hooks/usePreserveScroll'
import { DefaultLayout } from '@/components/layout/DefaultLayout'

import '@formatjs/intl-datetimeformat/polyfill'
import '@formatjs/intl-datetimeformat/locale-data/it'

import { NextIntlProvider } from 'next-intl'
import { PostHogProvider } from 'posthog-js/react'

import { posthog } from '../lib/posthog'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps, router }: AppPropsWithLayout) {
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 10 } },
    }),
  )
  const getLayout =
    Component.getLayout ??
    ((page) => <DefaultLayout key={`DefaultLayout-${router.locale}`}>{page}</DefaultLayout>)
  usePreserveScroll()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <PostHogProvider client={posthog}>
        <NextIntlProvider messages={pageProps.messages}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps?.dehydratedState}>
              {getLayout(<Component {...pageProps} />)}
            </Hydrate>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </NextIntlProvider>
      </PostHogProvider>
    </>
  )
}
