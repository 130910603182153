import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  getGetCartQueryKey,
  useAddToCart,
  useEmptyCart,
  useGetCart,
  useRemoveFromCart,
  useSetCart,
  useSetCartItemQuantity,
} from '@/hooks/generated-queries/cart/cart'
import { SetCartBody } from '@/hooks/generated-queries/model'

export const useCartStore = (
  { shouldInvalidate }: { shouldInvalidate?: boolean } = { shouldInvalidate: true },
) => {
  const cartQuery = useGetCart({ query: { refetchInterval: 10 * 60 * 1000 } })
  const items = cartQuery.data?.ITEMS ?? []

  const queryClient = useQueryClient()

  const invalidateCart = useCallback(
    () => queryClient.invalidateQueries({ queryKey: getGetCartQueryKey() }),
    [queryClient],
  )

  const addToCartMutation = useAddToCart({
    mutation: {
      onSuccess() {
        if (shouldInvalidate) invalidateCart()
      },
    },
  })
  const addToCart = useCallback(
    ({ productId, quantity }: { productId: string; quantity?: number }) =>
      addToCartMutation.mutateAsync({ data: { ID_PRODOTTO: productId, QUANTITA: quantity } }),
    [addToCartMutation],
  )

  const setCartItemQuantityMutation = useSetCartItemQuantity({
    mutation: {
      onSuccess() {
        if (shouldInvalidate) invalidateCart()
      },
    },
  })
  const setQuantity = useCallback(
    ({ productId, quantity }: { productId: string; quantity?: number }) =>
      setCartItemQuantityMutation.mutateAsync({
        data: { ID_PRODOTTO: productId, QUANTITA: quantity },
      }),
    [setCartItemQuantityMutation],
  )

  const removeFromCartMutation = useRemoveFromCart({
    mutation: {
      onSuccess() {
        if (shouldInvalidate) invalidateCart()
      },
    },
  })
  const removeFromCart = useCallback(
    ({ productId, quantity }: { productId: string; quantity?: number }) =>
      removeFromCartMutation.mutateAsync({ data: { ID_PRODOTTO: productId, QUANTITA: quantity } }),
    [removeFromCartMutation],
  )

  const emptyCartMutation = useEmptyCart({
    mutation: {
      onSuccess() {
        if (shouldInvalidate) invalidateCart()
      },
    },
  })
  const empty = useCallback(() => emptyCartMutation.mutateAsync(), [emptyCartMutation])

  const setCartMutation = useSetCart({
    mutation: {
      onSuccess() {
        if (shouldInvalidate) invalidateCart()
      },
    },
  })
  const setCart = useCallback(
    (data: SetCartBody) => setCartMutation.mutateAsync({ data }),
    [setCartMutation],
  )

  return {
    items,
    cartQuery,
    mutations: {
      addToCartMutation,
      setCartItemQuantityMutation,
      removeFromCartMutation,
      emptyCartMutation,
      setCartMutation,
    },
    actions: {
      addToCart,
      setQuantity,
      removeFromCart,
      empty,
      setCart,
    },
  }
}
