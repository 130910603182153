//@ts-nocheck
/**
 * Generated by orval v6.12.0 🍺
 * Do not edit manually.
 * Datek eCommerce API
 * Datek eCommerce API
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { customInstance } from '../../httpClient'
import type { ErrorType } from '../../httpClient'
import type {
  AddToCartBody,
  AddToCartDefault,
  Cart,
  CartSummary,
  EmptyCartDefault,
  GetCartDefault,
  GetCartSummaryDefault,
  RemoveFromCartBody,
  RemoveFromCartDefault,
  SetCartBody,
  SetCartDefault,
  SetCartItemQuantityBody,
  SetCartItemQuantityDefault,
} from '.././model'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never

export const getCart = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Cart>({ url: `/api/cart`, method: 'get', signal }, options)
}

export const getGetCartQueryKey = () => [`/api/cart`]

export type GetCartInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCart>>>
export type GetCartInfiniteQueryError = ErrorType<GetCartDefault>

export const useGetCartInfinite = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<GetCartDefault>,
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCartQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCart>>> = ({ signal }) =>
    getCart(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof getCart>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetCartQueryResult = NonNullable<Awaited<ReturnType<typeof getCart>>>
export type GetCartQueryError = ErrorType<GetCartDefault>

export const useGetCart = <
  TData = Awaited<ReturnType<typeof getCart>>,
  TError = ErrorType<GetCartDefault>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCart>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCartQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCart>>> = ({ signal }) =>
    getCart(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCart>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const setCart = (
  setCartBody: SetCartBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cart>(
    {
      url: `/api/cart`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setCartBody,
    },
    options,
  )
}

export type SetCartMutationResult = NonNullable<Awaited<ReturnType<typeof setCart>>>
export type SetCartMutationBody = SetCartBody
export type SetCartMutationError = ErrorType<SetCartDefault>

export const useSetCart = <TError = ErrorType<SetCartDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setCart>>,
    TError,
    { data: SetCartBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof setCart>>, { data: SetCartBody }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return setCart(data, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof setCart>>, TError, { data: SetCartBody }, TContext>(
    mutationFn,
    mutationOptions,
  )
}
export const addToCart = (
  addToCartBody: AddToCartBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cart>(
    {
      url: `/api/cart/add`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addToCartBody,
    },
    options,
  )
}

export type AddToCartMutationResult = NonNullable<Awaited<ReturnType<typeof addToCart>>>
export type AddToCartMutationBody = AddToCartBody
export type AddToCartMutationError = ErrorType<AddToCartDefault>

export const useAddToCart = <TError = ErrorType<AddToCartDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addToCart>>,
    TError,
    { data: AddToCartBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addToCart>>,
    { data: AddToCartBody }
  > = (props) => {
    const { data } = props ?? {}

    return addToCart(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof addToCart>>,
    TError,
    { data: AddToCartBody },
    TContext
  >(mutationFn, mutationOptions)
}
export const removeFromCart = (
  removeFromCartBody: RemoveFromCartBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cart>(
    {
      url: `/api/cart/remove`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: removeFromCartBody,
    },
    options,
  )
}

export type RemoveFromCartMutationResult = NonNullable<Awaited<ReturnType<typeof removeFromCart>>>
export type RemoveFromCartMutationBody = RemoveFromCartBody
export type RemoveFromCartMutationError = ErrorType<RemoveFromCartDefault>

export const useRemoveFromCart = <
  TError = ErrorType<RemoveFromCartDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeFromCart>>,
    TError,
    { data: RemoveFromCartBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeFromCart>>,
    { data: RemoveFromCartBody }
  > = (props) => {
    const { data } = props ?? {}

    return removeFromCart(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof removeFromCart>>,
    TError,
    { data: RemoveFromCartBody },
    TContext
  >(mutationFn, mutationOptions)
}
export const setCartItemQuantity = (
  setCartItemQuantityBody: SetCartItemQuantityBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cart>(
    {
      url: `/api/cart/set-quantita`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setCartItemQuantityBody,
    },
    options,
  )
}

export type SetCartItemQuantityMutationResult = NonNullable<
  Awaited<ReturnType<typeof setCartItemQuantity>>
>
export type SetCartItemQuantityMutationBody = SetCartItemQuantityBody
export type SetCartItemQuantityMutationError = ErrorType<SetCartItemQuantityDefault>

export const useSetCartItemQuantity = <
  TError = ErrorType<SetCartItemQuantityDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setCartItemQuantity>>,
    TError,
    { data: SetCartItemQuantityBody },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setCartItemQuantity>>,
    { data: SetCartItemQuantityBody }
  > = (props) => {
    const { data } = props ?? {}

    return setCartItemQuantity(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof setCartItemQuantity>>,
    TError,
    { data: SetCartItemQuantityBody },
    TContext
  >(mutationFn, mutationOptions)
}
export const emptyCart = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Cart>({ url: `/api/cart/empty`, method: 'post' }, options)
}

export type EmptyCartMutationResult = NonNullable<Awaited<ReturnType<typeof emptyCart>>>

export type EmptyCartMutationError = ErrorType<EmptyCartDefault>

export const useEmptyCart = <
  TError = ErrorType<EmptyCartDefault>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof emptyCart>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof emptyCart>>, TVariables> = () => {
    return emptyCart(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof emptyCart>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  )
}
export const getCartSummary = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CartSummary>({ url: `/api/cart/summary`, method: 'get', signal }, options)
}

export const getGetCartSummaryQueryKey = () => [`/api/cart/summary`]

export type GetCartSummaryInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCartSummary>>
>
export type GetCartSummaryInfiniteQueryError = ErrorType<GetCartSummaryDefault>

export const useGetCartSummaryInfinite = <
  TData = Awaited<ReturnType<typeof getCartSummary>>,
  TError = ErrorType<GetCartSummaryDefault>,
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCartSummary>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCartSummaryQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCartSummary>>> = ({ signal }) =>
    getCartSummary(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof getCartSummary>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type GetCartSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getCartSummary>>>
export type GetCartSummaryQueryError = ErrorType<GetCartSummaryDefault>

export const useGetCartSummary = <
  TData = Awaited<ReturnType<typeof getCartSummary>>,
  TError = ErrorType<GetCartSummaryDefault>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCartSummary>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCartSummaryQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCartSummary>>> = ({ signal }) =>
    getCartSummary(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCartSummary>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}
