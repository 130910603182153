import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import Router from 'next/router'

export const baseURL = process.env.NEXT_PUBLIC_API_BASE ?? 'http://localhost:3000'

export const axiosInstance = Axios.create({ baseURL, withCredentials: true })
axiosInstance.interceptors.response.use(
  (value) => value,
  (error: AxiosError) => {
    if (error.response?.status === 401 && !Router.route.startsWith('/auth')) {
      Router.push('/auth/login')
    }
    throw error
  },
) // use your own URL here or environment variable

interface Options extends AxiosRequestConfig {
  returnWholeResponse?: boolean
}

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(config: AxiosRequestConfig, options?: Options): Promise<T> => {
  const source = Axios.CancelToken.source()
  const { _cursor: cursor, ...params } = config.params ?? {}
  if (cursor) {
    params.query = params.query ?? {}
    params.query.cursor = cursor
  }
  const promise = axiosInstance({
    ...config,
    params,
    ...options,
    cancelToken: source.token,
  }).then((response) => {
    if (options?.returnWholeResponse) return response

    return response.data
  })

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
