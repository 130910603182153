import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { DefaultSeo } from 'next-seo'

import { posthog } from '@/lib/posthog'
import { useGetMe } from '@/hooks/generated-queries/user/user'
import { Header } from '@/components/Header'
import { EmptyLayout } from '@/components/layout/EmptyLayout'
import { SearchModal } from '@/components/SearchModal'
import { Spinner } from '@/components/Spinner'

import backgroundImg from '../../../public/img/beams-basic.png'

export const DefaultLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const t = useTranslations()
  const [isSearchModalVisible, setSearchModalVisible] = useState(false)
  const router = useRouter()

  const meQuery = useGetMe({
    query: {
      retry: (failureCount, error) => {
        const statusCode = error?.response?.status

        if (statusCode === 401 || statusCode === 403) {
          router.push('/auth/login')
        }

        return failureCount <= 3
      },
    },
  })

  const user = meQuery?.data?.user
  const customer = meQuery?.data?.customer

  useEffect(() => {
    if (user) {
      posthog.identify(`user-${user.ID}`, {
        name: user.NOME + ' ' + user.COGNOME,
        email: user.EMAIL,
      })
    } else if (customer) {
      posthog.identify(`customer-${customer.CODICE}`, {
        name: customer.NOME,
        email: customer.EMAIL,
      })
    }
  }, [customer, user])

  const year = useMemo(() => new Date().getFullYear(), [])

  if (meQuery.isLoading) {
    return <Spinner />
  }

  if (meQuery.isError) {
    if ([401, 403].includes(meQuery?.error?.response?.status as number)) return null

    return (
      <EmptyLayout>
        <div className="flex min-h-screen flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
          {t('DefaultLayout.error')}
        </div>
      </EmptyLayout>
    )
  }

  return (
    <>
      <DefaultSeo
        titleTemplate="%s | Datek B2B s.r.l"
        description="B2B eCommerce per Datek s.r.l., azienda di commercio all’ingrosso di ferramenta, attrezzature, accessori, vernici, collanti, rivolta ad imprese artigianali ed industriali nel settore legno."
      />
      <SearchModal isOpen={isSearchModalVisible} onClose={() => setSearchModalVisible(false)} />
      <div className="bg-white relative">
        <div className="absolute inset-x-0 top-0 overflow-hidden pl-[50%] z-0">
          <Image
            className="-ml-[39rem] w-[113.125rem] max-w-none"
            src={backgroundImg}
            alt="Background"
          />
        </div>
        <Header onSearchClick={() => setSearchModalVisible(true)} />

        <main className="relative z-10">{meQuery.isSuccess && children}</main>

        <footer aria-labelledby="footer-heading" className="bg-white">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {/*<div className="border-t border-gray-200">
              <div className="pt-16 pb-20">
                <div className="md:flex md:justify-center">
                  <img
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                    className="h-8 w-auto"
                  />
                </div>
                <div className="mx-auto mt-16 max-w-5xl xl:grid xl:grid-cols-2 xl:gap-8">
                  <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                    <div className="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">Products</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {footerNavigation.products.map((item) => (
                            <li key={item.name} className="text-sm">
                              <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">Customer Service</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {footerNavigation.customerService.map((item) => (
                            <li key={item.name} className="text-sm">
                              <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">Company</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {footerNavigation.company.map((item) => (
                            <li key={item.name} className="text-sm">
                              <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">Legal</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {footerNavigation.legal.map((item) => (
                            <li key={item.name} className="text-sm">
                              <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-2 lg:gap-x-6 xl:gap-x-8">
                <div className="flex items-center rounded-lg bg-gray-100 p-6 sm:p-10">
                  <div className="mx-auto max-w-sm">
                    <h3 className="font-semibold text-gray-900">Sign up for our newsletter</h3>
                    <p className="mt-2 text-sm text-gray-500">
                      The latest news, articles, and resources, sent to your inbox weekly.
                    </p>
                    <form className="mt-4 sm:mt-6 sm:flex">
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        type="text"
                        autoComplete="email"
                        required
                        className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                      />
                      <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                        <button
                          type="submit"
                          className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white"
                        >
                          Sign up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="relative mt-6 flex items-center py-12 px-6 sm:py-16 sm:px-10 lg:mt-0">
                  <div className="absolute inset-0 overflow-hidden rounded-lg">
                    <img
                      src="https://tailwindui.com/img/ecommerce-images/footer-02-exclusive-sale.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center saturate-0 filter"
                    />
                    <div className="absolute inset-0 bg-indigo-600 bg-opacity-90" />
                  </div>
                  <div className="relative mx-auto max-w-sm text-center">
                    <h3 className="text-2xl font-bold tracking-tight text-white">
                      Get early access
                    </h3>
                    <p className="mt-2 text-gray-200">
                      Did you sign up to the newsletter? If so, use the keyword we sent you to get
                      access.{' '}
                      <a
                        href="#"
                        className="whitespace-nowrap font-bold text-white hover:text-gray-200"
                      >
                        Go now<span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="py-10 md:flex md:items-center md:justify-between border-t border-gray-200">
              <div className="text-center md:text-left">
                <p className="text-sm text-gray-500">
                  &copy; {year} {t('DefaultLayout.footer.copyright')}
                </p>
              </div>

              {/*<div className="mt-4 flex items-center justify-center md:mt-0">
                <div className="flex space-x-8">
                  {footerNavigation.bottomLinks.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-sm text-gray-500 hover:text-gray-600"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>*/}
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
