import React, { ReactNode } from 'react'
import Image from 'next/image'

import backgroundImg from '../../../public/img/beams-basic.png'

export const EmptyLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <>
      <div className="bg-white relative">
        <div className="absolute inset-x-0 top-0 overflow-hidden pl-[50%] z-0">
          <Image
            className="-ml-[39rem] w-[113.125rem] max-w-none"
            src={backgroundImg}
            alt="Background"
          />
        </div>
        <div className="relative z-10">{children}</div>
      </div>
    </>
  )
}
